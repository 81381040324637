import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { PLAIN_TEXT_FONT_SIZES } from '~/componentConfiguration'
import { Control, useController } from 'react-hook-form'

export function PlainTextFontSizePicker({
  label = 'Font size',
  name,
  control,
}: {
  label?: string
  name: string
  control: Control<any>
}) {
  const { field } = useController({ name, control })

  const { onChange, value } = field

  return (
    <div>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="block text-sm font-medium text-gray-700">
          {label}
        </RadioGroup.Label>
        <div className="mt-4 flex flex-wrap items-center">
          {Object.keys(PLAIN_TEXT_FONT_SIZES).map((icon, index) => (
            <RadioGroup.Option
              key={index}
              value={icon}
              className={({ active, checked }) =>
                classNames(
                  'ring-teal-500',
                  active && checked ? 'ring ring-offset-1' : '',
                  !active && checked ? 'ring-2' : '',
                  'relative mb-2 mr-2 flex cursor-pointer items-center justify-center rounded-full p-0.5  focus:outline-none'
                )
              }
            >
              <RadioGroup.Label as="span" className="sr-only">
                Font size
              </RadioGroup.Label>
              <span
                aria-hidden="true"
                className={classNames(
                  'flex h-10 w-10 items-center justify-center rounded-full border border-black border-opacity-10 bg-white font-mono text-xs uppercase'
                )}
              >
                {PLAIN_TEXT_FONT_SIZES[icon]}
              </span>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}
