import { SparklesIcon } from '@heroicons/react/solid'
import { CareerPage, Job } from '@prisma/client'
import photo from '~/images/example-photo-1.webp'
import { Block } from '~/types'
import { ICONS } from '~/componentConfiguration'

function renderIcon(iconName: string) {
  const Component = ICONS[iconName]

  return <Component className="h-6 w-6 text-white" aria-hidden="true" />
}

export function ContentPhotoLeftSticky({
  jobs: rawJobs,
  block,
  careerPage,
}: {
  jobs: Job[]
  block: Block
  careerPage: CareerPage
}) {
  if (!block.props) {
    return null
  }

  const image = (block.props!.imageUrl || '').replace(
    'https://s3.us-east-2.amazonaws.com/growhirestaging/',
    'https://d1icxrsvmhiz31.cloudfront.net/'
  )

  return (
    <div className="relative overflow-hidden pt-16 pb-16">
      <div className="">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-accent-color to-accent-color">
                  {block.props!.icon && renderIcon(block.props!.icon)}
                </span>
              </div>
              <div className="mt-6">
                <h2 className="gh-textwithimage-header text-3xl font-extrabold tracking-tight text-gray-900">
                  {block.props!.header}
                </h2>
                <p className="gh-textwithimage-text mt-4 text-lg text-gray-500">
                  {block.props!.text}
                </p>
                <div className="mt-6">
                  <a
                    href={block.props!.ctaUrl}
                    className="gh-textwithimage-cta inline-flex rounded-md border border-transparent bg-gradient-to-r from-accent-color to-accent-color bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-accent-color hover:to-accent-color"
                  >
                    {block.props!.ctaLabel}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="gh-textwithimage-image w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                // src={photo}
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // return (
  //   <div className="mt-24">
  //     <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
  //       <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
  //         <div>
  //           <div>
  //             <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-red-500 to-rose-500">
  //               <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
  //             </span>
  //           </div>
  //           <div className="mt-6">
  //             <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
  //               Title goes here and it can take even 2 or 3 lines because why not. Describe what you want.
  //             </h2>
  //             <p className="mt-4 text-lg text-gray-500">
  //               Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
  //               porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at.
  //               Lectus viverra dui tellus ornare pharetra.
  //             </p>
  //             <div className="mt-6">
  //               <a
  //                 href="/"
  //                 className="inline-flex bg-gradient-to-r from-red-500 to-rose-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-red-700 hover:to-rose-700"
  //               >
  //                 Get started
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
  //         <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
  //           <img
  //             className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
  //             src={photo}
  //             alt=""
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
