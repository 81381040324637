import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'

import type { Job } from '@prisma/client'
import type { Block } from '~/types'
import { ICONS } from '~/componentConfiguration'

function renderIcon(iconName: string) {
  const Component = ICONS[iconName]

  return <Component className="h-6 w-6 text-white" aria-hidden="true" />
}

export function Benefits({ jobs, block }: { jobs: Job[]; block: Block }) {
  return (
    <div className="relative bg-white py-16 sm:py-24">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="gh-benefits-label text-base font-semibold uppercase tracking-wider text-accent-color">
          {block.props?.label}
        </h2>
        <p className="gh-benefits-header mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {block.props?.header}
        </p>
        <p className="gh-benefits-description mx-auto mt-5 max-w-prose text-xl text-gray-500">
          {block.props?.subheader}
        </p>
        <div className="mt-12">
          <div className="grid auto-rows-fr grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {block.props?.items.map((item: any, index: number) => (
              <div key={`${item.title}-${index}`} className="pt-6">
                <div className="gh-benefits-item flow-root h-full rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-accent-color p-3 shadow-lg">
                        {item.icon && renderIcon(item.icon)}
                        {!item.icon && (
                          <CloudUploadIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </div>
                    <h3 className="gh-benefits-item-title mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {item.title}
                    </h3>
                    <p className="gh-benefits-item-text mt-5 text-base text-gray-500">
                      {item.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
