import { ClientOnly } from 'remix-utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper'
import type { Swiper as SwiperType } from 'swiper'
import { Job } from '@prisma/client'
import { Block } from '~/types'
import { ChevronLeftIcon, ChevronRightIcon } from 'heroicons-new/24/outline'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

export function PagedCarousel({ block }: { block: Block }) {
  const swiper = useRef<SwiperType | null>(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const hasMoreThanOneItem = block.props!.items && block.props!.items.length > 1

  const [prevEnabled, setPrevEnabled] = useState(hasMoreThanOneItem)

  useEffect(() => {
    if (hasMoreThanOneItem !== prevEnabled) {
      setPrevEnabled(hasMoreThanOneItem)
      if (hasMoreThanOneItem) {
        swiper.current?.enable()
      } else {
        swiper.current?.disable()
      }
    }
  }, [prevEnabled, hasMoreThanOneItem])

  if (!block.props) {
    return null
  }

  return (
    <section className="mx-auto max-w-md px-4 py-16 sm:max-w-3xl sm:px-6 lg:max-w-6xl lg:px-8">
      <div className="relative">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          enabled={hasMoreThanOneItem}
          // pagination={{ clickable: true }}
          autoplay={{ delay: 10000 }}
          loop
          onSwiper={(instance) => (swiper.current = instance)}
          onSlideChange={(instance) => setCurrentSlideIndex(instance.realIndex)}
        >
          {block.props!.items &&
            block.props!.items.length > 0 &&
            block.props!.items.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <Slide item={item} index={index} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="bottom-0 z-50 mt-4 bg-white py-4 px-2 md:absolute md:mt-0">
          <button
            onClick={() => {
              swiper.current!.slidePrev()
            }}
            className="cursor-pointer text-gray-500"
          >
            <ChevronLeftIcon className="h-12 w-12" />
          </button>
          <button
            onClick={() => {
              swiper.current!.slideNext()
            }}
            className="ml-12 cursor-pointer text-gray-500"
          >
            <ChevronRightIcon className="h-12 w-12" />
          </button>
        </div>
      </div>
      <div className="mt-24 hidden gap-x-12 md:grid md:auto-cols-fr md:grid-flow-col">
        {block.props!.items &&
          block.props!.items.length > 0 &&
          block.props!.items.map((item: any, index: number) => (
            <button
              onClick={() => {
                swiper.current!.slideToLoop(index)
              }}
              key={index}
              className={classNames('flex text-left', {
                'text-accent-color': index === currentSlideIndex,
              })}
            >
              <div className="text-xl font-extrabold">
                {String(index + 1).padStart(2, '0')}
              </div>
              <div className="pl-4 text-xl">{item.title}</div>
            </button>
          ))}
      </div>
    </section>
  )
}

export function Slide({ item, index }: { item: any; index: number }) {
  const image = (item.imageUrl || '').replace(
    'https://s3.us-east-2.amazonaws.com/growhirestaging/',
    'https://d1icxrsvmhiz31.cloudfront.net/'
  )

  return (
    <div className="px-4 md:flex">
      <div className="shrink-0 pb-24 md:w-1/2">
        <h2 className="gh-benefits-label text-base font-semibold uppercase tracking-wider text-accent-color">
          {item.label}
        </h2>
        <p className="gh-benefits-header mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {item.title}
        </p>
        <p className="gh-textwithimage-text mt-4 text-lg text-gray-500">
          {item.text}
        </p>
        {item.ctaLabel && (
          <a
            href={item.ctaUrl}
            className="gh-pagedcarousel-cta mt-4 inline-flex rounded-md border border-transparent bg-gradient-to-r from-accent-color to-accent-color bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-accent-color hover:to-accent-color"
          >
            {item.ctaLabel}
          </a>
        )}
      </div>
      <div className="ml-auto mt-8 md:mt-0">
        {/* <img src="https://images.unsplash.com/photo-1586227740560-8cf2732c1531?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3122&q=80" alt="" /> */}
        <img src={item.imageUrl} alt="" className="md:h-96" />
      </div>
    </div>
  )
}
