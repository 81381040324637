import { ClientOnly } from 'remix-utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import type { Swiper as SwiperType } from 'swiper'
import { Job } from '@prisma/client'
import { Block } from '~/types'
import { useEffect, useRef, useState } from 'react'

export function Testimonials({ block }: { block: Block }) {
  const swiper = useRef<SwiperType | null>(null)

  const hasMoreThanOneItem = block.props!.items && block.props!.items.length > 1

  const [prevEnabled, setPrevEnabled] = useState(hasMoreThanOneItem)

  useEffect(() => {
    if (hasMoreThanOneItem !== prevEnabled) {
      setPrevEnabled(hasMoreThanOneItem)
      if (hasMoreThanOneItem) {
        swiper.current?.enable()
      } else {
        swiper.current?.disable()
      }
    }
  }, [prevEnabled, hasMoreThanOneItem])

  if (!block.props) {
    return null
  }

  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      enabled={hasMoreThanOneItem}
      autoplay={{ delay: 10000 }}
      onSwiper={(instance) => (swiper.current = instance)}
      loop
    >
      {block.props!.items &&
        block.props!.items.length > 0 &&
        block.props!.items.map((item: any, index: number) => (
          <SwiperSlide key={index}>
            <Testimonials1 item={item} />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

export function Testimonials1({ item }: { item: any }) {
  const image = (item.imageUrl || '').replace(
    'https://s3.us-east-2.amazonaws.com/growhirestaging/',
    'https://d1icxrsvmhiz31.cloudfront.net/'
  )

  return (
    <section className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-4 pt-20 pb-12 sm:px-6 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={784}
            height={404}
            fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
          />
        </svg>

        <svg
          className="absolute right-full top-1/2 hidden translate-x-1/2 -translate-y-1/2 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
          />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0">
            <img
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src={image}
              alt=""
            />
          </div>

          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-accent-color opacity-25"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="gh-testimonials-item-text text-2xl font-medium leading-9 text-gray-900">
                <p>{item.text}</p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="gh-testimonials-item-image h-12 w-12 rounded-full"
                      src={image}
                      alt=""
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="gh-testimonials-item-name text-base font-medium text-gray-900">
                      {item.name}
                    </div>
                    <div className="gh-testimonials-item-title text-base font-medium text-accent-color">
                      {item.title}
                    </div>
                  </div>
                </div>
                {item.ctaLabel && (
                  <a
                    href={item.ctaUrl}
                    className="gh-testimonials-item-cta mt-8 inline-flex rounded-md border border-accent-color px-4 py-2 text-base font-medium text-accent-color"
                  >
                    {item.ctaLabel}
                  </a>
                )}
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  )
}

export function Testimonials2() {
  return (
    <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          {/* <img
            className="mx-auto h-8"
            src="https://tailwindui.com/img/logos/workcation-logo-accent-color-mark-gray-800-and-accent-color-text.svg"
            alt="Workcation"
          /> */}
          <blockquote>
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
              <p>
                &ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nemo expedita voluptas culpa sapiente alias molestiae. Numquam
                corrupti in laborum sed rerum et corporis.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Judith Black
                  </div>

                  <svg
                    className="mx-1 hidden h-5 w-5 text-accent-color md:block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    CEO, HR Manager
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
