import { Link, useLoaderData } from '@remix-run/react'
import groupBy from 'lodash/groupBy'
import { LocationMarkerIcon } from '@heroicons/react/solid'

import type { Job } from '@prisma/client'

export function Jobs({ jobs: rawJobs }: { jobs: Job[] }) {
  const jobs = [...rawJobs]

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Senior Full-Stack Developer',
  //   locations: ['Tel Aviv-Yafo, Israel']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Senior SRE / DevOps',
  //   locations: ['Tel Aviv-Yafo, Israel']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Senior Flutter / Mobile Developer',
  //   locations: ['Tel Aviv-Yafo, Israel']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Account Manager',
  //   locations: ['New York, United States'],
  //   departments: ['Customer Success ']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Customer Support Specialist',
  //   locations: ['New York, United States'],
  //   departments: ['Customer Success ']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Product Marketing Director',
  //   locations: ['New York, United States'],
  //   departments: ['Marketing']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Senior Product Designer',
  //   locations: ['New York, United States'],
  //   departments: ['Product']
  // })

  // jobs.push({
  //   ...rawJobs[0],
  //   name: 'Product Manager',
  //   locations: ['New York, United States'],
  //   departments: ['Product']
  // })

  const groupedJobs = groupBy(jobs, (job) => job.departments[0] || 'General')

  return (
    <section
      className="divide-blue-gray-200 mx-auto max-w-md divide-y-2 py-4 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-24 lg:px-8 lg:pt-24 lg:pb-12"
      aria-labelledby="open-roles"
      id="open-roles"
    >
      {jobs.length === 0 && (
        <h2 className="mt-2 text-center text-xl font-semibold tracking-tight text-gray-700 sm:text-4xl">
          Currently we don't have any open positions
        </h2>
      )}
      {jobs.length > 0 && (
        <>
          <h2
            className="gh-jobslist-header mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            id="open-roles"
          >
            Current openings
          </h2>
          <div className="mt-6 pt-10">
            {Object.keys(groupedJobs).map((department) => (
              <div
                key={department}
                className="mx-auto max-w-7xl lg:grid lg:grid-cols-3 lg:gap-8"
              >
                <h2 className="gh-jobslist-department mb-8 text-2xl font-bold text-gray-700 sm:text-2xl lg:font-extrabold">
                  {department}
                </h2>
                <div className="col-span-2">
                  <ul className="">
                    {groupedJobs[department].map((job, index) => (
                      // temporary for mocks
                      <li key={job.id + 'i' + index}>
                        <Link to={`/jobs/${job.id}`} className="block">
                          <div className="px-0 pb-8 sm:px-6">
                            <div className="flex items-center justify-between">
                              <p className="truncate text-xl font-semibold text-gray-800">
                                {job.name}
                              </p>
                              <div className="ml-2 flex flex-shrink-0">
                                {/* <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                  Full-time
                                </p> */}
                              </div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                              <div className="sm:flex">
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                  <LocationMarkerIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {job.locations.join(', ')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </section>
  )
}
