import { Fragment, useContext, useEffect, useState } from 'react'
import type { CareerPage } from '@prisma/client'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { PencilIcon, XIcon } from '@heroicons/react/solid'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import {
  Block,
  EditFormComponent,
  EditFormComponentProps,
  PageConfiguration,
} from '~/types'
import { Disclosure } from '@headlessui/react'
import { IconPicker } from './IconPicker'

import {
  DragDropContext,
  Droppable,
  Draggable,
  DragDropContextProps,
} from 'react-beautiful-dnd'
import { CollectionIcon, TrashIcon } from '@heroicons/react/outline'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import { EditorContext } from '../EditorContext'
import { UppyUploader } from './UppyUploader'
import { ChevronLeftIcon } from 'heroicons-new/20/solid'

import cuid from 'cuid'

export function PagedCarouselForm({ block, onClose }: EditFormComponentProps) {
  const props = block.props!

  const context = useContext(EditorContext)

  const { onPropsUpdate, onBlockDelete } = context!

  const { register, watch, formState, control, reset } = useForm({
    defaultValues: {
      // label: props.label,
      // header: props.header,
      // subheader: props.subheader,
      items: props.items,
    },
  })

  const onCancel = () => {
    if (formState.isDirty) {
      reset(
        {
          items: props.items,
        },
        { keepDirty: false, keepDirtyValues: false }
      )
      onPropsUpdate(block, {
        items: props.items,
      })
    }
    setTimeout(() => onClose(), 0)
  }

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'items', // unique name for your Field Array
    }
  )

  // const watchedValues = watch()
  const watchedValues = useWatch({ control })

  const [wasDirty, setWasDirty] = useState(false)

  useEffect(() => {
    // don't send initial event
    if (!formState.isDirty && !wasDirty) {
      return
    }

    setWasDirty(true)

    if (onPropsUpdate) {
      onPropsUpdate(block, watchedValues)
    }
  }, [watchedValues, block, formState.isDirty])

  const onRemoveComponentClick = () => {
    if (window.confirm('Are you sure?')) {
      onBlockDelete(block)
      onClose()
    }
  }

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <nav className="flex" aria-label="Breadcrumb">
        <div className="flex items-center rounded-md bg-white px-6">
          <button
            onClick={onClose}
            className="border-b-2 border-transparent py-4 px-1 text-center text-sm font-medium text-gray-500 focus:outline-none"
          >
            <ChevronLeftIcon
              className="h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="sr-only">back</span>
          </button>
          <span className="border-b-2 border-transparent py-4 pl-2 text-center text-sm font-medium text-gray-500 focus:outline-none">
            Paged Carousel
          </span>
        </div>
      </nav>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="py-6 px-4 sm:px-6">
          <div className="mt-1">
            <p className="text-sm text-gray-700">
              Display a slideshow content with CTAs
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200">
            <div className="space-y-6 px-4 pt-6 pb-5 sm:px-6">
              {/* <div>
                <label className="block text-sm font-medium text-gray-900">
                  Label
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register('label')}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-900">
                  Header
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    // defaultValue={"Live and work from anywhere"}
                    {...register('header')}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                  Subheader
                </label>
                <div className="mt-1">
                  <textarea
                    {...register('subheader')}
                    rows={4}
                    className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div> */}
            </div>
            <div className="my-8 px-4 sm:px-6">
              <div className="mt-8 mb-4 flex items-center justify-center">
                <h4 className="text-base font-medium text-gray-900">Items</h4>
                <button
                  onClick={() => append({ id: cuid(), title: '', text: '' })}
                  className="ml-auto inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  add
                </button>
              </div>
              <div>
                <DraggableItemsList
                  control={control}
                  prefix="items"
                  fields={fields}
                  move={move}
                  remove={remove}
                  register={register}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          className="mr-auto flex items-center text-gray-400 hover:text-rose-500"
          onClick={onRemoveComponentClick}
        >
          <TrashIcon className="h-4 w-4" />
          <span className="ml-2 text-base text-sm font-normal">Remove</span>
        </button>
        {/* <a href="/" className="group inline-flex items-center text-sm text-gray-500 hover:text-gray-900 mr-auto">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <span className="ml-2"> Need help? </span>
        </a> */}
        <button
          type="button"
          onClick={() => onCancel()}
          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => onClose()}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Save draft
        </button>
      </div>
    </div>
  )
}

function DraggableItemsList({
  prefix,
  fields,
  register,
  move,
  remove,
  control,
}: {
  prefix: string
  fields: any[]
  register: any
  move: Function
  remove: Function
  control: any
}) {
  const onDragEnd: DragDropContextProps['onDragEnd'] = ({
    destination,
    source,
  }) => {
    if (!destination) {
      return
    }

    move(source.index, destination.index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(provided: any, snapshot: any) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id} index={index}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={provided.draggableProps.style}
                  >
                    <Disclosure key={field.id}>
                      <div className="divide-y divide-gray-200">
                        <div className="flex items-center space-x-4 py-4">
                          <div
                            className="flex items-center text-gray-400"
                            {...provided.dragHandleProps}
                          >
                            <DotsVerticalIcon className="-mr-3 h-4 w-4" />
                            <DotsVerticalIcon className="h-4 w-4" />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900">
                              {field.title
                                ? field.title!
                                : `Item #${index + 1}`}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <Disclosure.Button className="rounded-md p-1 hover:bg-gray-50">
                              {/* {({ open }) => open ? 'close' : 'edit'} */}
                              <PencilIcon className="h-6 w-5 text-gray-500" />
                            </Disclosure.Button>
                            <button
                              onClick={() => {
                                if (window.confirm('Are you sure?')) {
                                  remove(index)
                                }
                              }}
                              className="rounded-md p-1 hover:bg-gray-50"
                            >
                              <TrashIcon className="h-5 w-5 text-gray-500" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <Disclosure.Panel
                        as="div"
                        className="space-y-6 border-b border-gray-300 pt-6 pb-5"
                      >
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Picture
                          </label>
                          <div className="mt-1">
                            <UppyUploader
                              name={`items.${index}.imageUrl`}
                              control={control}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            Title
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              {...register(`items.${index}.title`)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            Label
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              {...register(`items.${index}.label`)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            Text
                          </label>
                          <div className="mt-1">
                            <textarea
                              {...register(`items.${index}.text`)}
                              rows={4}
                              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            CTA Label
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              {...register(`items.${index}.ctaLabel`)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            CTA URL
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              {...register(`items.${index}.ctaUrl`)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </Disclosure>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
