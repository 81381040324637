import { Hero } from '~/components/frontpage/Hero'
import { Footer } from '~/components/frontpage/Footer'
import { Benefits } from '~/components/frontpage/Benefits'
import { ContentPhotoLeft } from '~/components/frontpage/ContentPhotoLeft'
import { ContentPhotoLeftSticky } from '~/components/frontpage/ContentPhotoLeftSticky'
import { SimpleImageRight } from '~/components/frontpage/SimpleImageRight'
import { JobsGrid } from '~/components/frontpage/JobsGrid'
import { Jobs } from '~/components/frontpage/Jobs'
import { Testimonials } from '~/components/frontpage/Testimonials'
import { Team } from '~/components/frontpage/Team'
import { PagedCarousel } from './components/frontpage/PagedCarousel'

import { HeroForm } from './components/editor/forms/HeroForm'
import { EditFormComponent } from './types'
import { BenefitsForm } from './components/editor/forms/BenefitsForm'
import React from 'react'
import {
  CloudUploadIcon,
  GlobeIcon,
  PaperAirplaneIcon,
  EmojiHappyIcon,
  HeartIcon,
  BookOpenIcon,
  ClockIcon,
  CashIcon,
  CalculatorIcon,
  GiftIcon,
  LibraryIcon,
  MapIcon,
  MoonIcon,
  PuzzleIcon,
  ShoppingBagIcon,
  StarIcon,
  SparklesIcon,
  SunIcon,
  SupportIcon,
  TranslateIcon,
} from '@heroicons/react/outline'
import { ContentPhotoLeftStickyForm } from './components/editor/forms/ContentPhotoLeftStickyForm'
import { JobsForm } from './components/editor/forms/JobsForm'
import { TestimonialsForm } from './components/editor/forms/TestimonialsForm'
import { PagedCarouselForm } from './components/editor/forms/PagedCarouselForm'

import {
  BriefcaseIcon,
  DocumentTextIcon,
  HeartIcon as HeartIconNew,
  ComputerDesktopIcon,
  UserCircleIcon,
  ArrowsRightLeftIcon,
  Bars3BottomLeftIcon,
} from 'heroicons-new/24/outline'
import { TextWithHeader } from './components/frontpage/TextWithHeader'
import { TextWithHeaderForm } from './components/editor/forms/TextWithHeaderForm'

export const COMPONENT_MAP: { [k: string]: any } = {
  Hero: Hero,
  ContentPhotoLeftSticky: ContentPhotoLeftSticky,
  Benefits: Benefits,
  ContentPhotoLeft: ContentPhotoLeft,
  Jobs: Jobs,
  SimpleImageRight: SimpleImageRight,
  Team: Team,
  Testimonials: Testimonials,
  PagedCarousel: PagedCarousel,
  TextWithHeader: TextWithHeader,
}

export const COMPONENT_EDIT_MAP: {
  [k: string]: {
    component: EditFormComponent
    name: string
    description?: string
    color?: string
    icon?: any
    defaultValue: any
  }
} = {
  Hero: {
    component: HeroForm,
    name: 'Hero',
    description: 'Use this at the top of your career page',
    color: 'indigo-500',
    icon: ComputerDesktopIcon,
    defaultValue: {
      header: 'Live and work from anywhere',
      ctaLabel: 'Explore roles',
      imageUrl:
        'https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100',
      description:
        'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    },
  },
  Benefits: {
    component: BenefitsForm,
    name: 'Benefits',
    description: 'Brag about all the perks and upsides of working with you',
    color: 'teal-500',
    icon: HeartIconNew,
    defaultValue: {
      items: [
        {
          icon: 'HeartIcon',
          text: 'Get comprehensive medical, dental, and vision, plus a free subscription to One Medical.',
          title: 'Health and wellness',
        },
        {
          icon: 'PaperAirplaneIcon',
          text: 'We encourage you to take time off (seriously) and get the rest you need to do your best work.',
          title: 'Unlimited time off',
        },
        {
          icon: 'EmojiHappyIcon',
          text: 'We welcome new additions to your family with 12-18 weeks of parental leave.',
          title: 'Paid parental leave',
        },
        {
          icon: 'CashIcon',
          text: 'Save for your future with a 401(k) plan with match and share in Betterment’s success with company stock options.',
          title: '401(k) and equity',
        },
        {
          icon: 'CloudUploadIcon',
          text: 'We offer a flex allowance to use on wellness, lifestyle, and skill development.',
          title: 'Self care',
        },
        {
          icon: 'GiftIcon',
          text: 'Snacks, beverages, and catered lunches at our offices.',
          title: 'Free food',
        },
      ],
      label: 'Benefits',
      header: 'A well-balanced-life',
      subheader:
        'Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla.',
    },
  },
  ContentPhotoLeftSticky: {
    component: ContentPhotoLeftStickyForm,
    name: 'Text with image',
    description: 'Simple text block with an image on left',
    color: 'cyan-500',
    icon: DocumentTextIcon,
    defaultValue: {
      icon: 'HeartIcon',
      text: 'Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.',
      header:
        'Title goes here and it can take even 2 or 3 lines because why not.',
      ctaLabel: 'Explore roles',
      ctaUrl: '#open-roles',
      imageUrl:
        'https://images.unsplash.com/photo-1586227740560-8cf2732c1531?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3122&q=80',
    },
  },
  TextWithHeader: {
    component: TextWithHeaderForm,
    name: 'Text with header',
    description: 'A simple text block with an optional header',
    color: 'lime-500',
    icon: Bars3BottomLeftIcon,
    defaultValue: {
      header: '',
      text: "This is a placeholder section where you can highlight your company's values, culture, or mission. Remember, a well-crafted message here can effectively inspire potential applicants and reinforce their desire to be a part of your professional team.",
      fontSize: 'lg',
    },
  },
  Jobs: {
    component: JobsForm,
    name: 'Current openings',
    description: 'List of jobs from your ATS',
    color: 'red-500',
    icon: BriefcaseIcon,
    defaultValue: {},
  },
  Testimonials: {
    component: TestimonialsForm,
    name: 'Testimonials',
    description: 'What are your colleagues saying about your company?',
    color: 'indigo-500',
    icon: UserCircleIcon,
    defaultValue: {
      items: [
        {
          name: 'Francesca Keebler',
          title: 'Senior Compliance Associate',
          text: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        },
      ],
    },
  },
  PagedCarousel: {
    component: PagedCarouselForm,
    name: 'Paged Carousel',
    description: 'Display a slideshow content with CTAs',
    color: 'green-500',
    icon: ArrowsRightLeftIcon,
    defaultValue: {
      items: [],
    },
  },
  // ContentPhotoLeftSticky: HeroForm,
  // Benefits: HeroForm,
  // ContentPhotoLeft: HeroForm,
  // Jobs: HeroForm,
  // SimpleImageRight: HeroForm,
  // Team: HeroForm,
  // Testimonials: HeroForm
}

export const PLAIN_TEXT_FONT_SIZES: { [k: string]: string } = {
  sm: 'sm',
  base: 'md',
  lg: 'lg',
  '2xl': '2xl',
}

export const ICONS: { [k: string]: any } = {
  CloudUploadIcon: CloudUploadIcon,
  GlobeIcon: GlobeIcon,
  PaperAirplaneIcon: PaperAirplaneIcon,
  EmojiHappyIcon: EmojiHappyIcon,
  HeartIcon: HeartIcon,
  BookOpenIcon: BookOpenIcon,
  ClockIcon,
  CashIcon,
  CalculatorIcon,
  GiftIcon,
  LibraryIcon,
  MapIcon,
  MoonIcon,
  PuzzleIcon,
  ShoppingBagIcon,
  StarIcon,
  SparklesIcon,
  SunIcon,
  SupportIcon,
  TranslateIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  HeartIconNew,
  ComputerDesktopIcon,
  UserCircleIcon,
}
