import { Control, useController } from 'react-hook-form'

import Uppy from '@uppy/core'
// import Tus from '@uppy/tus'
import AwsS3 from '@uppy/aws-s3'
// import { DragDrop } from '@uppy/react'
import { useUppy, DashboardModal } from '@uppy/react'
// import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import { useEffect, useState } from 'react'

// const uppy = new Uppy({
//   // meta: { type: 'avatar' },
//   restrictions: { maxNumberOfFiles: 1 },
//   autoProceed: true,
// })

// uppy.use(AwsS3, {
//   limit: 2,
//   timeout: 3600000,
//   companionUrl: 'https://uppy-companion-95na.onrender.com/',
// })

// uppy.use(ImageEditor, {
//   id: 'ImageEditor',
//   // target: DashboardModal,
//   quality: 0.8,
// })

// // uppy.use(Tus, { endpoint: '/upload' })

// uppy.on('complete', (result: any) => {
//   const url = result.successful[0].uploadURL
//   console.log('----- file ulr', url)
//   // store.dispatch({
//   //   type: 'SET_USER_AVATAR_URL',
//   //   payload: { url },
//   // })
// })

type Props = {
  name: string
  control: Control<any>
  allowedFileTypes?: string[]
  hint?: string
}

export function UppyUploader({
  name,
  control,
  allowedFileTypes = undefined,
  hint,
}: Props) {
  const { field } = useController({ name, control })

  const { onChange, value } = field

  const uppy = useUppy(() => {
    return new Uppy({
      // meta: { type: 'avatar' },
      restrictions: { maxNumberOfFiles: 1, allowedFileTypes },
      autoProceed: false,
    })
      .use(ImageEditor, { id: 'ImageEditor' })
      .use(AwsS3, {
        limit: 2,
        timeout: 3600000,
        companionUrl: 'https://uppy-companion-95na.onrender.com/',
      })
  })

  useEffect(() => {
    uppy.on('complete', (result) => {
      const url = result.successful[0].uploadURL

      onChange(url)
    })
  }, [uppy, onChange])

  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => setModalOpen(false)

  return (
    <div className="mt-1 flex items-center">
      <span className="flex h-12 w-12 items-center justify-center overflow-hidden">
        {value && <img src={value} alt="avatar" className="h-full w-full" />}
        {!value && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-full w-full text-gray-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        )}
      </span>
      <button
        onClick={() => {
          setModalOpen(true)
        }}
        type="button"
        className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
      >
        Change
      </button>
      {hint && <p className="ml-4 text-xs text-gray-500">{hint}</p>}
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={modalOpen}
        onRequestClose={handleClose}
        plugins={['ImageEditor']}
        autoOpenFileEditor={true}
      />
    </div>
  )
}
