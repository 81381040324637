import classNames from 'classnames'
import type { Block } from '~/types'

export function TextWithHeader({ block }: { block: Block }) {
  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div
        className={classNames('mx-auto text-center', {
          'max-w-4xl':
            block.props?.fontSize === '2xl' && !block.props?.isFullWidth,
          'max-w-2xl':
            block.props?.fontSize !== '2xl' && !block.props?.isFullWidth,
        })}
      >
        {block.props?.header && block.props?.header?.length > 0 && (
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            {String(block.props?.header)}
          </h2>
        )}
        <p
          className={classNames(
            {
              'mt-6': block.props?.header && block.props?.header?.length > 0,
            },
            'leading-8 text-gray-600',
            block.props?.fontSize ? `text-${block.props?.fontSize}` : 'text-lg'
          )}
        >
          {String(block.props?.text)}
        </p>
      </div>
    </div>
  )
}
