import photo from '~/images/example-photo-1.webp'

export function SimpleImageRight() {
  return (
    <div className="mt-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Backed by world-renowned investors
            </h2>
            <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
              Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum
              urna sed consectetur neque tristique pellentesque. Blandit amet,
              sed aenean erat arcu morbi. Cursus faucibus nunc nisl netus morbi
              vel porttitor vitae ut. Amet vitae fames senectus vitae.
            </p>
            <div className="mt-6">
              <a href="/" className="text-base font-medium text-accent-color">
                Meet our investors and advisors &rarr;
              </a>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <img src={photo} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}
