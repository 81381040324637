import { CareerPage } from '@prisma/client'
import * as React from 'react'
import { EditFormComponentProps, PageConfiguration } from '~/types'

export type EditorContextParams = {
  careerPage: CareerPage
  currentState: PageConfiguration
  onPropsUpdate: Function
  onBlockAdd: Function
  onBlockDelete: Function
  onPageConfigurationUpdate: Function
}

export const EditorContext = React.createContext<EditorContextParams | null>(
  null
)

export const EditorProvider: React.FC<{
  value: EditorContextParams
  children?: JSX.Element
}> = ({ children, value }) => {
  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  )
}
