import {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
  ChevronRightIcon,
  StarIcon,
} from '@heroicons/react/solid'
import groupBy from 'lodash/groupBy'

import type { CareerPage, Job } from '@prisma/client'
import { Block } from '~/types'

export function Hero({
  jobs: rawJobs,
  block,
  careerPage,
}: {
  jobs: Job[]
  block: Block
  careerPage: CareerPage
}) {
  const jobs = [...rawJobs]

  const groupedJobs = groupBy(jobs, (job) => job.departments[0] || 'General')
  const groupedJobsByLocation = groupBy(
    jobs,
    (job) => job.locations[0] || 'Remote'
  )

  const heroImage = (block.props!.imageUrl || '').replace(
    'https://s3.us-east-2.amazonaws.com/growhirestaging/',
    'https://d1icxrsvmhiz31.cloudfront.net/'
  )

  return (
    <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
        <div>
          <div className="mt-8 lg:mt-20">
            <div>
              <a href="#open-roles" className="inline-flex space-x-4">
                <span className="rounded bg-accent-color px-2.5 py-1 text-xs font-semibold uppercase tracking-wide text-white">
                  {jobs.length} role{jobs.length !== 1 ? 's' : ''}
                </span>
                <span className="inline-flex items-center space-x-1 text-sm font-medium text-accent-color">
                  <span>
                    in {Object.keys(groupedJobsByLocation).length} location
                    {Object.keys(groupedJobsByLocation).length > 1 ? 's' : ''}
                  </span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </a>
            </div>
            <div className="mt-6 sm:max-w-xl">
              <h1 className="gh-hero-header text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {String(block.props!.header)}
              </h1>
              <p className="gh-hero-text mt-6 text-xl text-gray-500">
                {String(block.props!.description) ||
                  'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.'}
              </p>
            </div>
            <a
              href="#open-roles"
              className="gh-hero-cta mt-12 inline-block rounded-md border border-transparent bg-accent-color px-5 py-3 text-base font-medium text-white shadow hover:bg-accent-color focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:px-10"
            >
              {String(block.props!.ctaLabel) || 'Explore roles'}
            </a>
          </div>
        </div>
      </div>

      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="hidden sm:block">
            <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
            <svg
              className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </svg>
          </div>
          <div className="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
            <img
              className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
              // src={heroImage}
              src={heroImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
