import { useContext, useEffect, useMemo, useState } from 'react'
import type { CareerPage } from '@prisma/client'
import { useForm, useWatch } from 'react-hook-form'
import { XIcon, TrashIcon } from '@heroicons/react/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import {
  Block,
  EditFormComponent,
  EditFormComponentProps,
  PageConfiguration,
} from '~/types'
import { PhotoUploadInput } from '~/components/PhotoUploadInput'
import { PhotoUploader } from './PhotoUploader'
import { UppyUploader } from './UppyUploader'
import { EditorContext } from '../EditorContext'
import {
  HomeIcon,
  ArrowSmallLeftIcon,
  ArrowLeftIcon,
  XMarkIcon,
  ChevronLeftIcon,
} from 'heroicons-new/20/solid'

export function HeroForm({ block, onClose }: EditFormComponentProps) {
  const context = useContext(EditorContext)

  const { onPropsUpdate, onBlockDelete } = context!

  const props = block.props!

  const { register, watch, formState, control, reset } = useForm({
    defaultValues: {
      header: props.header,
      description: props.description,
      ctaLabel: props.ctaLabel,
      imageUrl: props.imageUrl,
    },
  })

  const watchedValues = useWatch({ control })

  const [wasDirty, setWasDirty] = useState(false)

  useEffect(() => {
    // don't send initial event
    if (!formState.isDirty && !wasDirty) {
      return
    }

    setWasDirty(true)

    if (onPropsUpdate) {
      onPropsUpdate(block, watchedValues)
    }
  }, [watchedValues, block, formState.isDirty])

  const onRemoveComponentClick = () => {
    if (window.confirm('Are you sure?')) {
      onBlockDelete(block)
      onClose()
    }
  }

  const onCancel = () => {
    if (formState.isDirty) {
      reset(
        {
          header: props.header,
          description: props.description,
          ctaLabel: props.ctaLabel,
          imageUrl: props.imageUrl,
        },
        { keepDirty: false, keepDirtyValues: false }
      )
      onPropsUpdate(block, {
        header: props.header,
        description: props.description,
        ctaLabel: props.ctaLabel,
        imageUrl: props.imageUrl,
      })
    }

    setTimeout(() => onClose(), 0)
  }

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <nav className="flex" aria-label="Breadcrumb">
        <div className="flex items-center rounded-md bg-white px-6">
          <button
            onClick={onClose}
            className="border-b-2 border-transparent px-1 py-4 text-center text-sm font-medium text-gray-500 focus:outline-none"
          >
            <ChevronLeftIcon
              className="h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="sr-only">back</span>
          </button>
          <span className="border-b-2 border-transparent py-4 pl-2 text-center text-sm font-medium text-gray-500 focus:outline-none">
            Hero
          </span>
        </div>
      </nav>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="px-4 py-6 sm:px-6">
          <div className="mt-1">
            <p className="text-sm text-gray-700">
              Use this at the top of your career page
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium text-gray-900"
                >
                  {' '}
                  Header{' '}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    // defaultValue={"Live and work from anywhere"}
                    {...register('header')}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-900"
                >
                  {' '}
                  Block text{' '}
                </label>
                <div className="mt-1">
                  <textarea
                    {...register('description')}
                    rows={4}
                    className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium text-gray-900"
                >
                  {' '}
                  CTA Label{' '}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register('ctaLabel')}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Image
                </label>
                <div className="mt-1">
                  <UppyUploader name="imageUrl" control={control} />
                </div>
              </div>
            </div>
            <div className="pb-6 pt-4">
              <div className="mt-4 flex text-sm">
                <a
                  href="/"
                  className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                >
                  <QuestionMarkCircleIcon
                    className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2"> Need help? </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          className="mr-auto flex items-center text-gray-400 hover:text-rose-500"
          onClick={onRemoveComponentClick}
        >
          <TrashIcon className="h-4 w-4" />
          <span className="ml-2 text-base text-sm font-normal">Remove</span>
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => onClose()}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Save draft
        </button>
      </div>
    </div>
  )
}
