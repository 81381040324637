import { useContext, useEffect } from 'react'
import type { CareerPage } from '@prisma/client'
import { useForm } from 'react-hook-form'
import { XIcon, TrashIcon } from '@heroicons/react/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import {
  Block,
  EditFormComponent,
  EditFormComponentProps,
  PageConfiguration,
} from '~/types'
import { PhotoUploadInput } from '~/components/PhotoUploadInput'
import { PhotoUploader } from './PhotoUploader'
import { UppyUploader } from './UppyUploader'
import { EditorContext } from '../EditorContext'
import { ChevronLeftIcon } from 'heroicons-new/20/solid'

export function JobsForm({ block, onClose }: EditFormComponentProps) {
  const props = block.props!

  const context = useContext(EditorContext)

  const { onPropsUpdate, onBlockDelete } = context!

  const { register, watch, formState, control } = useForm({
    defaultValues: {},
  })

  const watchedValues = watch()

  useEffect(() => {
    if (!formState.isDirty) {
      return
    }

    if (onPropsUpdate) {
      onPropsUpdate(block, watchedValues)
    }
  }, [watchedValues, block, onPropsUpdate, formState.isDirty])

  const onRemoveComponentClick = () => {
    if (window.confirm('Are you sure?')) {
      onBlockDelete(block)
      onClose()
    }
  }

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <nav className="flex" aria-label="Breadcrumb">
        <div className="flex items-center rounded-md bg-white px-6">
          <button
            onClick={onClose}
            className="border-b-2 border-transparent py-4 px-1 text-center text-sm font-medium text-gray-500 focus:outline-none"
          >
            <ChevronLeftIcon
              className="h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="sr-only">back</span>
          </button>
          <span className="border-b-2 border-transparent py-4 pl-2 text-center text-sm font-medium text-gray-500 focus:outline-none">
            Current openings
          </span>
        </div>
      </nav>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="py-6 px-4 sm:px-6">
          <div className="mt-1">
            <p className="text-sm text-gray-700">List of jobs from your ATS</p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5"></div>
            <div className="pt-4 pb-6">
              <div className="mt-4 flex text-sm">
                <a
                  href="mailto:support@coderbyte.com"
                  className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                >
                  <QuestionMarkCircleIcon
                    className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2"> Need help? </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          className="mr-auto flex items-center text-gray-400 hover:text-rose-500"
          onClick={onRemoveComponentClick}
        >
          <TrashIcon className="h-4 w-4" />
          <span className="ml-2 text-base text-sm font-normal">Remove</span>
        </button>
        <button
          type="button"
          onClick={() => onClose()}
          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => onClose()}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Save draft
        </button>
      </div>
    </div>
  )
}
